/**
 * Modifies the string to be url safe though still readable, having onlu a-z0-9 + dashes.
 * E.g.
 * input: Trailer Beck Episode 32
 * output: trailer-beck-episode-32
 */
export const slugify = (...args: (string | number)[]): string => {
  const value = args.join(" ");

  return value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "")
    .replace(/\s+/g, "-");
};
